import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Button, Checkbox, ConfigProvider, Select, notification, Card, Result } from 'antd';
import { Link, useSearchParams } from 'react-router-dom';
import { CloseCircleOutlined, CheckCircleFilled } from '@ant-design/icons';
import changeHeight from '../data/changeHeight';
//Imports ajenos a el diseño
import axios from 'axios';
import aseguradoras from '../data/aseguradora';

const FormBlogCancelaSeguro = () => {

    const [buttonLoading, setbuttonLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [primaryColor, setPrimaryColor] = useState('#FFFFFF');
    const [searchParams] = useSearchParams();
    const [form] = Form.useForm();
    const refForm = useRef(0);
    const refResult = useRef(0);
    const [result, setResult] = useState("collapse");
    const [urlResponse, seturlResponse] = useState("");
    const [heightResult, setHeightResult] = useState(0);
    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        if (searchParams.get("primaryColor")) {
            setPrimaryColor('#' + searchParams.get("primaryColor"));
            setResult("none");
            setHeightResult(refResult.current.clientHeight);
        }
        if (searchParams.get("parentUrl")) {
            form.setFieldsValue({ url: decodeURIComponent(searchParams.get("parentUrl")) });
        }
    }, [searchParams]);

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
          for (let entry of entries) {
            if (entry.target === refForm.current) {
              changeHeight(entry.target.clientHeight);
              break;
            }
          }
        });
    
        resizeObserver.observe(refForm.current);
    
        return () => {
          resizeObserver.unobserve(refForm.current);
        };
      }, []);

    const onFinish = (values) => {
        setbuttonLoading(true);
        axios.post(`${process.env.REACT_APP_URL_BLOG_CANCELAR}`, values)
            .then(function (response) {
                setbuttonLoading(false);
                setSuccess(true);
                setResult("block");
                changeHeight(heightResult);
                seturlResponse(response.data.carta_url);
            })
            .catch(function () {
                openNotification('bottom');
                setbuttonLoading(false);
            });
    };

    const openNotification = (placement) => {
        api.info({
            message: 'Error inesperado',
            description:
                'Ha ocurrido un error inesparado. Intentelo más tarde. Disculpe las molestias',
            placement,
            icon: (
                <CloseCircleOutlined style={{ color: "#f7151c" }} />
            )
        });
    };

    return (
        <>
            {contextHolder}
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: primaryColor,
                    },
                }}
            >
                <style>
                    {`
                    ::-webkit-scrollbar {
                        display: none;
                    }
                    `}
                </style>
                <div ref={refForm}>
                    <Card
                        title="Formulario para recibir tu carta de anulación"
                        style={{ width: '100%', backgroundColor: "#f0f2f5" }}
                        headStyle={
                            {
                                backgroundColor: primaryColor,
                                color: "white"
                            }
                        }
                    >
                        <div>
                            <Form
                                form={form}
                                style={{ padding: '1.5rem' }}
                                hidden={success}
                                name="basic"
                                initialValues={{ remember: true }}
                                layout='vertical'
                                onFinish={onFinish}
                            >
                                <Form.Item
                                    hidden={true}
                                    label="URL"
                                    name="url"
                                >
                                    <Input type='text' />
                                </Form.Item>

                                <Form.Item
                                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>Nombre y apellidos del asegurado</p>}
                                    name="name"
                                    style={{ marginBottom: "0.6rem" }}
                                    rules={[{ required: true, message: 'Por favor, ingresa tu nombre completo' }]}
                                >
                                    <Input placeholder='Nombre y apellidos' type='text' disabled={buttonLoading} />
                                </Form.Item>

                                <Form.Item
                                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>Número de DNI del asegurado</p>}
                                    name="dni"
                                    style={{ marginBottom: "0.6rem" }}
                                    rules={[{ required: true, message: 'Por favor, introduce el DNI asegurado' }]}
                                >
                                    <Input placeholder='12345678A' type='text' disabled={buttonLoading} />
                                </Form.Item>

                                <Form.Item
                                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>Nombre de la aseguradora</p>}
                                    name="aseguradora"
                                    style={{ marginBottom: "0.6rem" }}
                                    rules={[{ required: true, message: 'Por favor, escoge tu banco o aseguradora' }]}
                                >
                                    <Select showSearch placeholder='Escoge tu banco o aseguradora' disabled={buttonLoading}>
                                        {aseguradoras.map((aseguradora) => (
                                            <Select.Option key={aseguradora} value={aseguradora}>{aseguradora}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>Ramo</p>}
                                    name="ramo"
                                    style={{ marginBottom: "0.6rem" }}
                                    rules={[{ required: true, message: 'Por favor, escoge el ramo de tu seguro' }]}
                                >
                                    <Select showSearch placeholder='Escoge tu banco o aseguradora' disabled={buttonLoading}>
                                        <Select.Option key='Vida' value='Vida'>Vida</Select.Option>
                                        <Select.Option key='Salud' value='Salud'>Salud</Select.Option>
                                        <Select.Option key='Hogar' value='Hogar'>Hogar</Select.Option>
                                        <Select.Option key='Auto' value='Auto'>Coche/Moto</Select.Option>
                                        <Select.Option key='Accidentes' value='Accidentes'>Accidentes</Select.Option>
                                        <Select.Option key='Otros' value='Otros'>Otros</Select.Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>Referencia / Número de póliza</p>}
                                    name="poliza"
                                    style={{ marginBottom: "0.6rem" }}
                                    rules={[{ required: true, message: 'Por favor, introduzca su referencia o número de póliza' }]}
                                >
                                    <Input type='text' disabled={buttonLoading} />
                                </Form.Item>

                                <Form.Item
                                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>Teléfono</p>}
                                    name="telefono"
                                    style={{ marginBottom: "0.6rem" }}
                                    rules={[{ required: true, message: 'Por favor, introduzca su teléfono' }]}
                                >
                                    <Input type='tel' maxLength={9} disabled={buttonLoading} />
                                </Form.Item>

                                <Form.Item
                                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>Email</p>}
                                    name="email"
                                    style={{ marginBottom: "0.6rem" }}
                                    rules={[{ required: true, message: 'Por favor, introduzca su correo electrónico' }]}
                                >
                                    <Input type='text' disabled={buttonLoading} />
                                </Form.Item>


                                <Form.Item style={{marginBottom: "0.6rem", marginBottom: "-6px"}} valuePropName="checked" name="terms" initialValue={false} rules={[{ required: true, validator: async (_, checked) => { if (!checked) { return Promise.reject(new Error("Por favor, acepta los términos y condiciones")); } } }]}>
                                    <Checkbox disabled={buttonLoading} >Acepto los <Link target='_blank' to='https://www.coventia.es/politica-de-privacidad-y-aviso-de-cookies'>términos y condiciones</Link></Checkbox>
                                </Form.Item>

                                <Form.Item style={{marginBottom: "0.6rem"}} valuePropName="checked" name="interested_price" initialValue={false}>
                                    <Checkbox disabled={buttonLoading} >Deseo que me ofrezcan un mejor precio</Checkbox>
                                </Form.Item>

                                <Form.Item>
                                    <Button type='primary' loading={buttonLoading} htmlType="submit">
                                        {buttonLoading ? 'Enviando...' : 'Recibir la carta para cancelar mi seguro'}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                        <div ref={refResult}>
                            <div style={{ display: `${result}`, padding: '1.5rem' }}>
                                {/* <p>¡Muchas gracias {form.getFieldValue('name')}! Hemos enviado a tu email la carta para que solicites la cancelación de tu seguro.</p> */}
                                {/* <Button type='primary' href={urlResponse}>Descargar carta</Button> */}
                                {} <Result
                                    icon={<CheckCircleFilled style={{ color: primaryColor }} />}
                                    style={{ display: `${result}`, padding: '1.5rem', paddingTop: '3rem' }}
                                    status="success"
                                    title="¡Hecho! Revisa tu email"
                                    subTitle={`Muchas gracias ${form.getFieldValue('name')}! Hemos enviado a tu email la carta para que solicites la cancelación de tu seguro.`}
                                />
                            </div>
                        </div>
                    </Card>
                </div>
            </ConfigProvider>
        </>
    );
}

export default FormBlogCancelaSeguro;
import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Card, Alert } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

/*
 * Componente para gestionar los beneficiarios en los dinstintos formularios de coventia-forms-frontend.
 * 
 * Props:
 * - form: Instancia del formulario AntD proporcionada por useForm(). (Mirar en el componente <Form></Form> de AntD)
 * - designado: Estado que determina si se muestran los beneficiarios (Establecer la visibilidad del componente).
 * - buttonLoading: Booleano que indica si el botón de enviar está en estado de carga. (Deshabilita los campos si es true).
 * - setCanSubmit: Función para actualizar el estado de la capacidad de enviar el formulario en el componente principal. (Habilita o deshabilita el botón de enviar de el Form padre).
 * 
 * Funcionalidad:
 * - Gestiona la entrada de datos de los beneficiarios incluyendo nombre completo, DNI y porcentaje.
 * - Realiza validaciones dinámicas para asegurar que la suma de los porcentajes de los beneficiarios sea exactamente 100%.
 * - Muestra una alerta si la suma de los porcentajes no es 100% y deshabilita el envío del formulario hasta que se corrija.
 */


const BeneficiariosFormComponent = ({ form, designado, buttonLoading, setCanSubmit }) => {
    const [isTotalPercentageError, setIsTotalPercentageError] = useState(false);

    // Calcula la suma de los porcentajes para validar que sea 100%.
    const handlePercentageChange = () => {
        const newBeneficiarios = form.getFieldValue('beneficiarios') || [];
        const totalPercentage = newBeneficiarios.reduce((acc, curr) => acc + Number(curr?.porcentaje || 0), 0);
        setIsTotalPercentageError(designado === "Personas designadas" && totalPercentage !== 100);
        setCanSubmit(totalPercentage === 100);
    };

    // Efecto para manejar cambios en los beneficiarios y actualizar la validación de porcentajes.
    useEffect(() => {
        if (designado === "Personas designadas") {
            handlePercentageChange();
        }
        if (designado === "Cláusula estándar") {
            setCanSubmit(true);
        }
    }, [form.getFieldValue('beneficiarios'), designado]); // eslint-disable-line

    return (
        <Form.Item
            label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>Personas designadas *</p>}
            tooltip={{ title: "Hay que indicar el nombre completo, DNI y porcentaje. El porcentaje total debe ser del 100%" }}
            style={{ display: `${designado === "Personas designadas" ? 'block' : 'none'}`, marginBottom: "0.6rem" }}
        >
            <Form.List
                name="beneficiarios"
                rules={[{ required: designado === "Personas designadas", message: 'Por favor, indica a las personas designadas como se indica en la pista' }]}
            >
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }, index) => (
                            <Card key={key} title={`Beneficiario ${index + 1}`} style={{ marginBottom: 16, backgroundColor: 'rgba(0, 0, 0, 0.02)', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
                                <MinusCircleOutlined onClick={() => remove(name)} style={{ color: 'red', marginBottom: 16 }} />
                                <Form.Item
                                    {...restField}
                                    label="Nombre completo"
                                    name={[name, 'nombreCompleto']}
                                    rules={[{ required: true, message: 'Nombre completo es requerido' }]}
                                >
                                    <Input disabled={buttonLoading} />
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    label="DNI"
                                    name={[name, 'dni']}
                                    rules={[{ required: true, message: 'DNI es requerido' }]}
                                >
                                    <Input disabled={buttonLoading} />
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    label="Porcentaje"
                                    name={[name, 'porcentaje']}
                                    rules={[{ required: true, message: 'Porcentaje es requerido' }]}
                                >
                                    <Input
                                        disabled={buttonLoading}
                                        onChange={handlePercentageChange}
                                    />
                                </Form.Item>
                            </Card>
                        ))}
                        {fields.length < 6 && (
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    Añadir beneficiario
                                </Button>
                            </Form.Item>
                        )}
                    </>
                )}
            </Form.List>
            {isTotalPercentageError && (
                <Alert
                    message="¡Importante!"
                    description="La suma de los porcentajes de todos los beneficiarios debe ser exactamente 100%."
                    type="warning"
                    showIcon
                />
            )}
        </Form.Item>
    );
};

export default BeneficiariosFormComponent;
